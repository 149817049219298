body, html {
  color: $body-color;
  -webkit-font-smoothing: antialiased;
}

.text-muted {
  color: $body-secondary-color !important;
}
.text-body,a.text-body {
  color: $body-color !important;
}
p {
  font-size: $fsP;
}
.text-bg-dark {
  color: $body-color-dark;
}


svg {
  @each $name, $color in $colors {
    &.text-#{$name} {
      path, circle, rect {
        fill: #{$color};
      }

      &:hover {
        path, circle, rect {
          fill: #{$color};
        }
      }
    }

    &.stroke-#{$name} {
      path, circle, rect {
        stroke: #{$color};
      }

      &:hover {
        path, circle, rect {
          stroke: #{$color};
        }
      }
    }
  }
}

path, circle, rect {
  @each $name, $color in $colors {
    &.text-#{$name} {
      fill: #{$color};

      &:hover {
        fill: #{$color};
      }
    }
    &.bg-#{$name} {
      fill: #{$color};
    }

    &.stroke-#{$name} {
      stroke: #{$color};

      &:hover {
        stroke: #{$color};
      }
    }
  }
}

//PROJECT STYLES
@import "buttons";
@import "forms";
@import "custom";
@import "badge";
@import "aside";
@import "nav";
@import "chip";
@import "dropdown";
@import "type";
@import "animation";
@import "shadows";
@import "table";
@import "modals";
@import "toast";
@import "tabs";
.body-bg {
  background-color: $body-bg;
}
.text-overflow-ellipsis {
  text-overflow: ellipsis;
}
.disabled > * {
  opacity: 0.25;
  cursor: not-allowed !important;
}
.right-0 {
  right: 0;
}


  @include media-breakpoint-up(xl) {
    .lg-position-absolute {
      position: absolute;
    }
  }

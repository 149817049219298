$font-family-base: "Open Sans", sans-serif,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"; //All text
$headings-font-family: "Open Sans", sans-serif,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"; //h1-h6

////Typography
$fs16: 1rem;
$fs14: 0.875rem;
$fs13: 0.8125rem;
$fs12: 0.75rem ;
$fs11: 0.69rem ;
$font-size-base: $fs14;
$font-size-sm: $fs13;
$font-size-lg: $fs16;
$font-size-xs: $fs12;
$font-size-xss: $fs11;
$fsP: $font-size-lg;
$font-sizes: (
    md: $font-size-base,
    lg: $font-size-lg, 
    xs: $font-size-xs,
    sm: $font-size-sm,
    xss: $font-size-xss,
    display: 2.38rem, // 38px
    display-sm: 1.5rem, // 24px
  
);

$line-height-base: 1.4;
$line-height-sm: 1.25;
$line-height-xs: 1;

$h1-font-size:1.31rem; // 21px
$h1-letter-spacing:-0.05rem;
$h1-line-height:$line-height-sm;

$h2-font-size:1.25rem; // 20px
$h1-letter-spacing:-0.05rem;
$h2-line-height:$line-height-sm;

$h3-font-size:1.113rem; // 18px
$h3-line-height:$line-height-sm;

$h4-font-size:1rem; // unused
$h4-line-height:1.125rem;

$h5-font-size: 0.938rem; // unused
$h5-line-height:$line-height-sm;

$font-weight-bold: 700;
$font-weight-regular: 400;
$font-weight-semibold: 600;

$headings-font-weight: $font-weight-bold;
$headings-margin-bottom: $spacer * 2;

$btn-font-size: $font-size-lg;
$btn-font-weight: 600;
$input-font-size: $font-size-base;
$th-font-size: $font-size-sm;
// Start of Font information
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/OpenSans-Regular.ttf) format("truetype");
  font-display: block;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: url(/fonts/OpenSans-SemiBold.ttf) format("truetype");
  font-display: block;
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url(/fonts/OpenSans-Bold.ttf) format("truetype");
  font-display: block;
}

.logo {
  font-size: 20px;
  font-weight: 800;
  letter-spacing: -1px;
  &--small {
    font-size: $h1-font-size;
  }
}

// End of Font information

//Spacers

.page-container {
  max-width: 1360px;
  margin: 0;
  padding-top: 0;
  @media (min-width: map-get($grid-breakpoints, md)) {
    padding-top: $spacer * 7;
  }
  h1 {
    line-height: 40px;
  }
  padding-bottom: $spacer*10;
}

.dropdown-menu {
  border:none;
  overflow: hidden;
  border-radius: $border-radius;
  box-shadow: $shadow-3;
}

.bg-info.opacity-10 { // override info message
  border-color: $action100 !important;
  background-color: $action100 !important;
}

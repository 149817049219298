$side-width: 550px;
.side-modal {
  overflow-y: hidden !important;
  opacity: 1 !important;
  z-index: 10000000;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .modal-body {
    height: calc(100% - 60px);
    overflow-y: auto;
  }

  .modal-dialog {
    box-shadow: rgba(75, 75, 75, 0.11) 0px 1px 6px 0px,
    rgba(75, 75, 75, 0.11) 0px 1px 4px 0px;
    transition: transform 0.15s ease-in-out;
    transform: translate(100%, 0%) !important;
    position: absolute;
    max-width: 1000px;
    width: $side-width !important;
    height: 100% !important;
    max-height: 100% !important;
    margin: 0;
    right: 0;
    left: auto;
  }

  &.show {
    .modal-dialog {
      transform: translate(0%, 0%) !important;
    }
  }

  &__footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    height: 130px;
  }

  .modal-content {
    border-radius: 0;
    width: 100%;
    height: 100%;
  }
  &.create-feature-modal {
    .modal-body {
      overflow-y: hidden;
    }
    &.overflow-y-auto {
      .modal-body {
        overflow-y: auto;
      }
    }
  }
}

@include color-mode(dark) {
  .bg-body {
    background: $body-bg
  }
  $theme-colors: map-merge($colors, (
          light: $dark500,
          light100: $dark300,
          light200: $dark400,
          light300: $dark500,
          light400: $dark500,
          light500: $dark500,
          light600: $dark500,
          dark: $light500,
          dark100: $light100,
          dark200: $light200,
          dark300: $light300,
          dark400: $light400,
          dark500: $light500,
  ));

  $dropdown-bg: $dark;
  // Import all the project files
  @import "form_variables";
  @import "../project/index";
  @import "../3rdParty/index";

  //workaround for text, it doesn't pickup theme colors
  @each $name, $hex in $theme-colors {
    .text-#{$name} { //workaround for lack of text-Theme support in dark mode
      color: $hex !important;
    }
    .bg-#{$name} { //workaround for lack of text-Theme support in dark mode
      background-color: $hex !important;

      &.bg-opacity-10 {
        background-color: rgba($hex, 0.1) !important;
      }
    }
    .hover-bg-#{$name}:hover { //workaround for lack of text-Theme support in dark mode
      background-color: $hex !important;

      &.bg-opacity-10 {
        background-color: rgba($hex, 0.1) !important;
      }
    }
  }
}

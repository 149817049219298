@import "variables/index";
@import "styles/3rdParty/index";
@import "project/index";

.cursor-pointer {
  cursor: pointer;
}

.position-relative {
  position: relative;
}

.flex-row {
  display: flex;
}
.hidden-theme-light {
  display: none;
}
.hidden-theme-dark {
  display: block;
}
@include color-mode(dark) {
  .hidden-theme-light {
    display: block;
  }
  .hidden-theme-dark {
    display: none;
  }
}
code {
  padding-top: $spacer*2;
  text-wrap: wrap;
}
.flex-1 {
  flex:1;
}

$navbar-height: 100px;
.navbar-toggler {
  line-height: 55px;
}
.nav-link {
  font-weight: 600;
  line-height: normal;
  color: $body-color !important;
  .fa {
    font-size: 13px;
    opacity: 0.6;
    margin-right: 10px;
  }
  padding: $spacer $spacer*2 !important;
  border-radius: $border-radius;
  &:hover {
    background-color: $primaryAlpha8;
  }
  &.active {
    background-color: $primaryAlpha8;
  }
}
.navbar {
  height: $navbar-height;
  border-bottom: 1px solid $hr-border-color;
  background-color: $navbar-bg;
}
.btn.navbar-toggle-option {
  background-color: transparent;
  border: none;
  border-radius: 0;
  cursor: pointer;
  width: $navbar-height;
  height: $navbar-height;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}
